import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FaPlug, FaChartBar, FaBullhorn, FaBookmark, FaCog, FaShareAlt, FaUsers} from 'react-icons/fa';
import {TbSocial} from 'react-icons/tb';

const shortcutContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '40px',
};

const shortCutStyle = {
    width: '300px',
    height: '200px',
    marginBottom: '20px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '15px',
    backgroundColor: '#fff',
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2rem',
    justifyContent: 'center',
    margin: '0 20px 20px 0',
    transition: 'box-shadow 0.3s ease',
};

const iconStyle = {
    marginRight: '10px',
    fontSize: '3rem',
};

const ShortCuts = () => {
    const [isHovered, setIsHovered] = useState(null);

    const handleHover = (index) => {
        setIsHovered(index);
    };

    const handleLeave = () => {
        setIsHovered(null);
    };

    const shortcutsData = [
        {id: 1, title: 'Connectors', to: '/connectors', icon: <FaPlug/>},
        {id: 2, title: 'Fans', to: '/fans', icon: <FaUsers/>},
        {id: 3, title: 'Analytics', to: '/analytics', icon: <FaChartBar/>},
        {id: 4, title: 'Marketing', to: '/marketing', icon: <FaBullhorn/>},
        {id: 5, title: 'Bookmarks', to: '/bookmarks', icon: <FaBookmark/>},
        {id: 6, title: 'Settings', to: '/settings', icon: <FaCog/>},
    ];

    return (
        <div style={shortcutContainerStyle}>
            {shortcutsData.map((card, index) => (
                <NavLink
                    key={card.id}
                    to={card.to}
                    style={{
                        ...shortCutStyle,
                        boxShadow: isHovered === index ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                    onMouseOver={() => handleHover(index)}
                    onMouseLeave={handleLeave}
                >
                    <div style={iconStyle}>{card.icon}</div>
                    <div>{card.title}</div>
                </NavLink>
            ))}
        </div>
    );
};

export default ShortCuts;
